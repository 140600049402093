export default {

    paginas:[
        {
            tipotela:'telacheia',
            htmlContentEsquerda:{
                titulo:`E-Spot`,
                subtitulo:`(Utilizacao)`
            },
            htmlContentDireita:{
                conteudo:``
            }
        },
        {
            tipotela:'telacomimagem',
            htmlContentEsquerda:{
                img:`/Treinamentos/espot/dashboard.jpg`,
                css:`css:'width:50%!important;height:80%!important;'`
            },
            htmlContentDireita:{
                conteudo:`<h1>Gerenciamento de Acessos a Rede</h1>
                            <ul>
                                <li> - Consultar Internautas</li>
                                <li> - Ativar e Desativar Internautas</li>
                                <li> - Cadastrar Usuarios Colaboradores</li>
                                <li> - Configurações</li>
                            </ul>
                        `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                // linkVideo:'https://esvideos.economysoftware.com.br/videostutoriais/espot/1-Espot-Login.mp4'
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/espot/1-Espot-Login.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Tela de Login</h1>
                <ul>
                    <li> - Login de Colaboradores</li>
                    <li> - Recuperação de Senha</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/espot/2-Espot-Dashboard.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>DashBoard</h1>
                <ul>
                    <li> - Sessões Ativas</li>
                    <li> - Usuários</li>
                    <li> - Velocidades</li>
                    <li> - Bloqueios</li>
                    <li> - Cadastros</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/espot/3-Espot-Usuarios.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Usuarios</h1>
                <ul>
                    <li> - Clientes</li>
                    <li> - Visitantes</li>
                    <li> - Funcionarios</li>
                    <li> - Edição</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/espot/4-Espot-Visitantes.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Cadastro de Visitantes</h1>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/espot/5-Espot-Configuracoes.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Configurações</h1>
                <ul>
                    <li> - Cadastro de Colaboradores</li>
                    <li> - Publicidade</li>
                    <li> - Questionarios</li>
                    <li> - Personalização</li>
                    <li> - Personalização Login Cliente</li>
                    <li> - Config. Cadastro Visitante</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/espot/5.1-Protocolos-de-Atendimento.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Protocolos de Atendimento</h1>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/espot/6-Espot-Android.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Hospede acessando via Android</h1>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/espot/7-Espot-Iphone.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Hospede acessando via Iphone</h1>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/espot/8-Espot-Tablet.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Hospede acessando via Tablet</h1>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/espot/9-Espot-windows.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Hospede acessando via Windows</h1>`
            }
        },
        {
            tipotela:'telacheia',
            htmlContentEsquerda:{
                titulo:`Obrigado!!`,
                subtitulo:``
            },
            htmlContentDireita:{
                conteudo:``
            }
        },
    ]

}