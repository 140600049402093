export default {

    paginas:[
        {
            tipotela:'telacheia',
            htmlContentEsquerda:{
                titulo:`FD Guest - Avaliações`,
                subtitulo:`(Utilizacao)`
            },
            htmlContentDireita:{
                conteudo:``
            }
        },
        {
            tipotela:'telacomimagem',
            htmlContentEsquerda:{
                img:`/Treinamentos/fdguest/avaliacoes.jpg`,
                css:`css:'width:50%!important;height:80%!important;'`
            },
            htmlContentDireita:{
                conteudo:`<h1>Avaliações</h1>
                            <ul>
                                <li> - Funcionalidade voltada para coleta de feedback dos hospedes.</li>
                                <li> - Avaliações de diversas areas</li>
                                <li> - Pode ser cofigurada usando como base a data do Check in e a data do Check out</li>
                            </ul>
                        `
            }
        },
        {
            tipotela:'telacomimagem',
            htmlContentEsquerda:{
                img:`/Treinamentos/fdguest/email_recebido_avaliacao_hospede.jpg`,
                css:`css:'width:45%!important;height:70%!important;'`
            },
            htmlContentDireita:{
                conteudo:`<h1>Emails enviados para o hospede</h1>
                            <ul>
                                <li> - Emails são enviados automaticamente.</li>
                                <li> - O envio do email pode ser configurado para X dias após o check in.</li>
                                <li> -O envio do email pode ser configurado para X dias após o check out.</li>
                            </ul>
                        `
            }
        },
        {
            tipotela:'telacomimagem',
            htmlContentEsquerda:{
                img:`/Treinamentos/fdguest/tela-avaliacao.jpg`,
                css:`css:'width:45%!important;height:70%!important;'`
            },
            htmlContentDireita:{
                conteudo:`<h1>Tela de Review</h1>
                            <ul>
                                <li> - Quando o hospede clica no link recebido ele é redirecionado para a tela de avaliações.</li>
                                <li> - O hospede faz o login com email e o número do apartamento.</li>
                                <li> - Será exibida todas as avaliações disponivel no momento, cujo o hospede esteja apto a responder.</li>
                            </ul>
                        `
            }
        },
        {
            tipotela:'telacomimagem',
            htmlContentEsquerda:{
                img:`/Treinamentos/fdguest/tela_acompanhamento_respostas.jpg`,
                css:`css:'width:45%!important;height:70%!important;'`
            },
            htmlContentDireita:{
                conteudo:`<h1>Acompanhamento das respostas</h1>
                            <ul>
                                <li> - Acesso as respostas dos hospedes.</li>
                                <li> - Acompanhamento das médias.</li>
                            </ul>
                        `
            }
        },
        {
            tipotela:'telacomimagem',
            htmlContentEsquerda:{
                img:`/Treinamentos/fdguest/alerta-nota-baixa.jpg`,
                css:`css:'width:45%!important;height:60%!important;'`
            },
            htmlContentDireita:{
                conteudo:`<h1>Alerta de notas baixas</h1>
                            <ul>
                                <li> - Configure alertas quando a media de uma avaliação for negativa.</li>
                                <li> - Alertas através de email para que seja possivel contornar a situação.</li>
                            </ul>
                        `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguest-avaliacoes/fdguest-criando-avaliacoes.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Como cadastrar uma avaliação nova</h1>
                <ul>
                    <li> - Login de Colaboradores</li>
                    <li> - Recuperação de Senha</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguest-avaliacoes/fdguest-hospede-avaliando.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Recebendo e Prencheendo uma Avaliação</h1>
                <ul>
                    <li> - Recebendo a avaliação no Email</li>
                    <li> - Preencheendo a avaliação</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguest-avaliacoes/fdguest-visualizando-as-respostas.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Visualizando as respostas</h1>
                <ul>
                    <li> - Acompanhe a média por avaliação</li>
                    <li> - Confira todas as respostas</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguest-avaliacoes/fdguest-criando-categorias.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Cadastrando Categorias de Perguntas</h1>
                <ul>
                    <li></li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguest-avaliacoes/fdguest-criando-portais.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Cadastrando Portais de Avaliação</h1>
                <ul>
                    <li></li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguest-avaliacoes/fdguest-configuracao-avaliacao.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Cadastrando Portais de Avaliação</h1>
                <ul>
                    <li></li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacheia',
            htmlContentEsquerda:{
                titulo:`Obrigado!!`,
                subtitulo:``
            },
            htmlContentDireita:{
                conteudo:``
            }
        },
    ]

}