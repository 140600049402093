export default {

    paginas:[
        {
            tipotela:'telacheia',
            htmlContentEsquerda:{
                titulo:`FD Guest - Camareiras`,
                subtitulo:`(Utilizacao)`
            },
            htmlContentDireita:{
                conteudo:``
            }
        },
        {
            tipotela:'telacomimagem',
            htmlContentEsquerda:{
                img:`/Treinamentos/fdguest/fdguest1.jpg`,
                css:`css:'width:50%!important;height:80%!important;'`
            },
            htmlContentDireita:{
                conteudo:`<h1>FD Guest</h1>
                            <ul>
                                <li> - O FDGuest é uma ferramenta que visa auxiliar no relacionamento com os clientes do hotel (CRM).</li>
                                <li> - Integrada ao PMS, envia, automaticamente, um email convidando o hóspede a entrar na ferramenta (módulo web hóspede)</li>
                                <li> - O email com o link para o aplicativo é enviado uma vez na ocasião do checkin</li>
                                <li> - Na tela ao lado, um exemplo do email recebido</li>
                            </ul>
                        `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestcamareiras/camareiras-web.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Camareiras Versão Web</h1>
                <ul>
                    <li> - Gestão dos Status dos Quartos</li>
                    <li> - Definição de Status e Ocorrêcias</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestcamareiras/camereiras-mobile.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Camareiras Versão Mobile</h1>
                <ul>
                    <li> - Gestão dos Status dos Quartos</li>
                    <li> - Definição de Status e Ocorrêcias</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacheia',
            htmlContentEsquerda:{
                titulo:`Obrigado!!`,
                subtitulo:``
            },
            htmlContentDireita:{
                conteudo:``
            }
        },
    ]

}