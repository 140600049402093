<template style="" >
        <div class="telacheia semborda">
            <v-container id="containervv" fluid class="telacheiacommenu semborda telaTitulo">
                <v-row class="semborda">
                <v-col cols="12" md="6" style="display:flex;flex-direction:column;align-items:center;justify-content:center" class="telacheia semborda">
                    <div style="width:90%;max-width:90%;height:80%!important;max-height:80%!important;display:flex;justify-content:center;align-items:center;">
                        <img  :style="htmlContentEsquerda.css" :src="htmlContentEsquerda.img" />
                        <video id="video" controls class="videos">
                            <source id="videosource" :src="htmlContentEsquerda.linkVideo" type="video/mp4">
                        </video>                       
                    </div>
                </v-col>
                <v-col cols="12" md="6" class="semborda" >
                        <div v-html="htmlContentDireita.conteudo" style="width:100%;height:90vh;display:flex;flex-direction:column;align-items:center;justify-content:center;color:white;">
                        </div>                    
                </v-col>
                </v-row> 
            </v-container>  
            
            <div style="background-color:#0477FF;height:10%;display:flex;align-items:center;justify-content:center;">
                        <v-btn large elevation="" color="error" @click="()=>{this.$emit('voltar')}">Anterior</v-btn>
                        <span style="margin-left:30px;font-weight:bold;font-size:16px;color:white;">{{pagina}}</span>
                        <v-btn large elevation="" color="success" style="margin-left:30px;" @click="()=>{this.$emit('proxima');}" >Proximo</v-btn>
            </div>    
        </div>  
</template>

<script>

export default {
    name:'TelaComVideo',
    props:["htmlContentEsquerda","htmlContentDireita","linkVideo","pagina"],    
    components: {
    },
    data(){
        return{
            // htmlContent:null,
        }
    },
    watch: {
        htmlContentEsquerda(htmlContentEsquerda){
            console.log(htmlContentEsquerda.linkVideo)
            document.getElementById('video').load()
        }
    },
    methods:{
        abrirLink(){
            window.open(this.htmlContentEsquerda.linkVideo, '_blank');
        }
    },
    async mounted(){
    }
    
}
</script>

<style>
    .videos{
        position:absolute;
        margin-left:0px;
        margin-top:-20vh;
        width:38vw;
        height:45vh;
    }

    .telacheiacommenu{
        width:100vw!important;
        height:90vh!important;
        right: 0px!important;
        left: 0px!important;
    }
    
    .telacheia{
        width:100vw!important;
        height:100vh!important;
        right: 0px!important;
        left: 0px!important;
    }

    .semborda{
        padding:0px!important;
        margin:0px!important;
    }

    .telaTitulo{
        background-color: #0477FF;        
    }
</style>