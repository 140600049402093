import { render, staticRenderFns } from "./TelaCheia.vue?vue&type=template&id=a6cae076&style=true&"
import script from "./TelaCheia.vue?vue&type=script&lang=js&"
export * from "./TelaCheia.vue?vue&type=script&lang=js&"
import style0 from "./TelaCheia.vue?vue&type=style&index=0&id=a6cae076&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports