export default {

    paginas:[
        {
            tipotela:'telacheia',
            htmlContentEsquerda:{
                titulo:`FD Guest - Solicitações`,
                subtitulo:`(Utilizacao)`
            },
            htmlContentDireita:{
                conteudo:``
            }
        },
        {
            tipotela:'telacomimagem',
            htmlContentEsquerda:{
                img:`/Treinamentos/fdguest/fdguest1.jpg`,
                css:`css:'width:50%!important;height:70%!important;'`
            },
            htmlContentDireita:{
                conteudo:`<h1>FD Guest</h1>
                            <ul>
                                <li> - O FDGuest é uma ferramenta que visa auxiliar no relacionamento com os clientes do hotel (CRM).</li>
                                <li> - Integrada ao PMS, envia, automaticamente, um email convidando o hóspede a entrar na ferramenta (módulo web hóspede)</li>
                                <li> - O email com o link para a aplicação é enviado após o checkin</li>
                                <li> - O acesso também pode ser disponibilizado por QR Code</li>
                            </ul>
                        `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestrecepcao/recepcao-hospede.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Acesso do Hospede</h1>
                <ul>
                    <li> - Como o hospede acessar o FD Guest</li>
                    <li> - Quais os serviços na area Recepção</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestrecepcao/recepcao-cadastro-servicos.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Cadastro de Serviços</h1>
                <ul>
                    <li> - Como Editar um serviço já existente</li>
                    <li> - Como cadastrar um novo serviço</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestrecepcao/recepcao-atendimento-chamados.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Atendendo chamados</h1>
                <ul>
                    <li> - Recebendo Chamados</li>
                    <li> - Visualizando Chamados</li>
                    <li> - Negando</li>
                    <li> - Atendendo Chamados</li>
                    <li> - Negando Chamados</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacheia',
            htmlContentEsquerda:{
                titulo:`Obrigado!!`,
                subtitulo:``
            },
            htmlContentDireita:{
                conteudo:``
            }
        },
    ]

}