<template style="" >
    <div style="" id="divcontainer" class="telacheia semborda" >
        <TelaCheia :pagina="indicePagina+1" @proxima="proxima" @voltar="voltar" v-if="exibirTela=='telacheia'" :htmlContentEsquerda="htmlContentEsquerda" :htmlContentDireita="htmlContentDireita" />  
        <TelaComImagem :pagina="indicePagina+1" @proxima="proxima" @voltar="voltar" v-if="exibirTela=='telacomimagem'" :htmlContentEsquerda="htmlContentEsquerda" :htmlContentDireita="htmlContentDireita" />  
        <TelaComVideo :pagina="indicePagina+1" @proxima="proxima" @voltar="voltar" v-if="exibirTela=='telacomvideo'" :htmlContentEsquerda="htmlContentEsquerda" :htmlContentDireita="htmlContentDireita" />  
    </div>
</template>

<script>

import TelaCheia from './Treinamentos/Templates/TelaCheia.vue'
import TelaComImagem from './Treinamentos/Templates/TelaComImagem.vue'
import TelaComVideo from './Treinamentos/Templates/TelaComVideo.vue'
import ApresentacaoEspot from './Treinamentos/objetosTreinamentos/ApresentacaoEspot.js'
import ApresentacaoFDGuestCamareiras from './Treinamentos/objetosTreinamentos/ApresentacaoFDGuestCamareiras.js'
import ApresentacaoFDGuestRestaurante from './Treinamentos/objetosTreinamentos/ApresentacaoFDGuestRestaurante.js'
import ApresentacaoFDGuestRecepcao from './Treinamentos/objetosTreinamentos/ApresentacaoFDGuestRecepcao.js'
import ApresentacaoFDGuestManutencao from './Treinamentos/objetosTreinamentos/ApresentacaoFDGuestManutencao.js'
import ApresentacaoFDGuestAvaliacoes from './Treinamentos/objetosTreinamentos/ApresentacaoFDGuestAvaliacoes.js'
import ApresentacaoFDGuestSolicitacoes from './Treinamentos/objetosTreinamentos/ApresentacaoFDGuestSolicitacoes.js'

export default {
    name:'Robos',
    components: {
        TelaCheia,
        TelaComImagem,
        TelaComVideo
    },
    data(){
        return{
            htmlContentEsquerda:null,
            htmlContentDireita:null,
            exibirTela:'',
            indicePagina:0,
            apresentacao:null,
        }
    },
    watch: {
        
    },
    methods:{
            proxima(){
                if(this.indicePagina < this.apresentacao.paginas.length -1){
                    this.indicePagina++
                    this.htmlContentEsquerda = this.apresentacao.paginas[this.indicePagina].htmlContentEsquerda
                    this.htmlContentDireita = this.apresentacao.paginas[this.indicePagina].htmlContentDireita
                    this.exibirTela = this.apresentacao.paginas[this.indicePagina].tipotela
                    localStorage.setItem('indicePagina',this.indicePagina)
                }
            },
            voltar(){
                if(this.indicePagina > 0){
                    this.indicePagina--
                    this.htmlContentEsquerda = this.apresentacao.paginas[this.indicePagina].htmlContentEsquerda
                    this.htmlContentDireita = this.apresentacao.paginas[this.indicePagina].htmlContentDireita
                    this.exibirTela = this.apresentacao.paginas[this.indicePagina].tipotela
                    localStorage.setItem('indicePagina',this.indicePagina)
                }
            }
    },

    
    async mounted(){

        if(this.$route.query.sistema == 'espot'){ this.apresentacao = ApresentacaoEspot }
        if(this.$route.query.sistema == 'fdguest-camareiras'){ this.apresentacao = ApresentacaoFDGuestCamareiras }
        if(this.$route.query.sistema == 'fdguest-restaurante'){ this.apresentacao = ApresentacaoFDGuestRestaurante }
        if(this.$route.query.sistema == 'fdguest-recepcao'){ this.apresentacao = ApresentacaoFDGuestRecepcao }
        if(this.$route.query.sistema == 'fdguest-manutencao'){ this.apresentacao = ApresentacaoFDGuestManutencao }
        if(this.$route.query.sistema == 'fdguest-avaliacoes'){ this.apresentacao = ApresentacaoFDGuestAvaliacoes }
        if(this.$route.query.sistema == 'fdguest-solicitacoes'){ this.apresentacao = ApresentacaoFDGuestSolicitacoes }

        if(localStorage.getItem('indicePagina')){
            this.indicePagina = parseInt(localStorage.getItem('indicePagina'))
            if(this.indicePagina >  (this.apresentacao.paginas.length)){
                localStorage.setItem('indicePagina',0)
            }
        }
        
        this.htmlContentEsquerda = this.apresentacao.paginas[this.indicePagina ].htmlContentEsquerda
        this.htmlContentDireita = this.apresentacao.paginas[this.indicePagina ].htmlContentDireita
        this.exibirTela = this.apresentacao.paginas[this.indicePagina ].tipotela

    }
    
}
</script>

<style>
    .telacheiacommenu{
        width:100vw!important;
        height:90vh!important;
        right: 0px!important;
        left: 0px!important;
    }
    
    .telacheia{
        width:100vw!important;
        height:100vh!important;
        right: 0px!important;
        left: 0px!important;
    }

    .semborda{
        padding:0px!important;
        margin:0px!important;
    }

    .telaTitulo{
        background-color: #0477FF;        
    }

    h1{
        font-size: 40px;
    }

    li{
        font-size:20px;
        margin-top:20px;
    }
</style>