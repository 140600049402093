<template style="" >
        <div class="telacheia semborda">
            <v-container id="containervv" fluid class="telacheiacommenu semborda telaTitulo">
                <v-row class="semborda">
                <v-col cols="12" md="6" style="display:flex;flex-direction:column;align-items:center;justify-content:center" class="telacheia semborda">
                    <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;height:60%;">
                        <h1 style="color:white;font-size:70px;">{{htmlContentEsquerda.titulo}}</h1>
                        <h3 style="color:#3c509e;font-size:30px;">{{htmlContentEsquerda.subtitulo}}</h3>
                    </div>
                 
                    <div style="height:30%;">
                        <v-img style="height:70px;max-height:100px;width:300px;max-width:350px;" src="/Treinamentos/logo-economy-apresentacao-mini.jpg"></v-img>
                    </div>
                </v-col>
                <v-col cols="12" md="6" class="semborda" >
                        <div style="width:100%;height:90vh;display:flex;align-items:flex-end;justify-content:flex-end;">
                                <v-img style="height:90vh;max-height:90vh;width:50%;max-width:400px;" src="/Treinamentos/logo-economy-apresentacao.jpg"></v-img>
                        </div>                    
                </v-col>
                </v-row> 
            </v-container>  
            
            <div style="background-color:#0477FF;height:10%;display:flex;align-items:center;justify-content:center;">
                        <v-btn large elevation="" color="error" @click="()=>{this.$emit('voltar')}">Anterior</v-btn>
                        <span style="margin-left:30px;font-weight:bold;font-size:16px;color:white;">{{pagina}}</span>
                        <v-btn large elevation="" color="success" style="margin-left:30px;" @click="()=>{this.$emit('proxima')}" >Proximo</v-btn>
            </div>    
        </div>  
</template>

<script>

export default {
    name:'TelaCheia',
    props:["htmlContentEsquerda","htmlContentDireita","linkVideo","pagina"],    
    components: {
    },
    data(){
        return{
            
        }
    },
    watch: {
        
    },
    methods:{
            
    },

    
    async mounted(){

    }
    
}
</script>

<style>
    .telacheiacommenu{
        width:100vw!important;
        height:90vh!important;
        right: 0px!important;
        left: 0px!important;
    }
    
    .telacheia{
        width:100vw!important;
        height:100vh!important;
        right: 0px!important;
        left: 0px!important;
    }

    .semborda{
        padding:0px!important;
        margin:0px!important;
    }

    .telaTitulo{
        background-color: #0477FF;        
    }
</style>